import React, { useMemo } from 'react';
import './TradeFairBooth.scss';
import type { Booth } from '../../types/tradeFair';
import useBoothPreOrders from '../../api/tradeFair/useBoothPreOrders';
import {
	CharlesTitle,
	ButtonLink
} from '@abm-international/react-components';
import TradeFairArticlesList from './TradeFairArticlesList';
import { useDispatch } from 'react-redux';
import { updatePreOrderActionArticleQuantity } from '../PreOrders/preOrdersActions';
import { PreOrderAction } from '~/types/preOrders';
import { Article } from '~/types/articles';
import useBooths from './../../api/tradeFair/useBooths';
import {
	FaArrowLeft,
	FaArrowRight
} from 'react-icons/fa';
import promoTypes from '../products/promoTypes';
//import useTotalWeight from './useTotalWeight';
import { t } from './../../libraries/i18n';
import { useParams } from 'react-router-dom';
import { tradeFairDates, tradeFairWeeks } from '../../constants';
import { usePoints } from '../../libraries/hooks/usePoints';
import dateHelper from '../../libraries/dateHelper';
import { useBoothsWithTotals } from './TradeFairOverview';
import { TradeFairPoints } from './TradeFairPoints';

interface TotalsPerWeek {
	weekNr: number;
	articles?: number;
	items?: number;
	freeItems?: number;
	standardPoints?: number;
	tradeFairPoints?: number;
}

const useTotalsByWeek = (preOrders: ReturnType<typeof useBoothPreOrders>['preOrders'], boothId: Booth['boothId']) => {
	const { calculateTotalPoints } = usePoints(tradeFairDates, undefined, true);

	const totals = useMemo(() => {
		const weeks: TotalsPerWeek[] = tradeFairWeeks.map(weekNr => {
			return {
				weekNr: Number(weekNr.split('.')[1]),
				...preOrders.reduce((totals, preOrder) => {
					if (boothId !== preOrder.boothId || preOrder.delivery.split('.')[1] !== weekNr.split('.')[1]) return totals;

					const { standardPoints, tradeFairPoints } = calculateTotalPoints(preOrder.articles, preOrder.quantities, dateHelper.getDateOfISOWeek(weekNr.split('.')[1], weekNr.split('.')[0]));

					const {
						articles, items, freeItems
					} = preOrder.articles.reduce((total, article) => {
						const quantity = preOrder.quantities?.[article.id];
						if (!quantity) return total;

						const freeItems = article.promo?.type !== promoTypes.FREE_BY_UNIT ? 0 : Math.floor(quantity / article.promo.trigger.num) * article.promo.target.num;

						return {
							articles: total.articles + (quantity ? 1 : 0),
							items: total.items + quantity,
							freeItems: total.freeItems + freeItems,
							standardPoints: total.standardPoints,
							tradeFairPoints: total.tradeFairPoints,
						};
					}, {
						articles: 0,
						items: 0,
						freeItems: 0,
						standardPoints: 0,
						tradeFairPoints: 0,
					});

					return {
						articles: totals.articles + articles,
						items: totals.items + items,
						freeItems: totals.freeItems + freeItems,
						standardPoints: totals.standardPoints + standardPoints,
						tradeFairPoints: totals.tradeFairPoints + tradeFairPoints,
					};
				}, {
					articles: 0,
					items: 0,
					freeItems: 0,
					standardPoints: 0,
					tradeFairPoints: 0,
				})
			};
		});


		weeks.forEach(week => {
			const [items, articles, freeItems] = preOrders.reduce((totals, preOrder) => {
				if (boothId !== preOrder.boothId) return totals;

				const poWeek = parseInt(preOrder.delivery.split('.')[1]);
				if (poWeek !== week.weekNr) return totals;

				const poItemsTotal = Object.values(preOrder.quantities).reduce((total, value) => total += value, 0);
				const poArticlesTotal = Object.values(preOrder.quantities).reduce((total, value) => total += value !== 0 ? 1 : 0, 0);
				const poItemsFreeTotal = preOrder.articles.reduce((total, article) => {

					if (!article.promo) return total;
					if (article.promo.type !== promoTypes.FREE_BY_UNIT) return total;

					const quantity = preOrder.quantities?.[article.id];
					if (!quantity || quantity === 0) return total;

					const freeItems = Math.floor(quantity / article.promo.trigger.num) * article.promo.target.num;

					return total + freeItems;
				}, 0);

				return [
					totals[0] + poItemsTotal,
					totals[1] + poArticlesTotal,
					totals[2] + poItemsFreeTotal
				];
			}, [0, 0, 0]);

			week.items = items;
			week.freeItems = freeItems;
			week.articles = articles;
		});

		return weeks;
	}, [preOrders, boothId]);

	return totals;
};

const usePreviousAndNextBooths = (booths: Booth[], boothId: Booth['boothId']) => {
	const boothIndex = booths.findIndex(b => b.boothId === boothId);

	return [
		booths?.[boothIndex - 1] ?? null,
		booths?.[boothIndex + 1] ?? null
	];
};

export default function TradeFairBooth(props: Booth) {
	const { boothId } = useParams<{ boothId: string }>();
	const { preOrders, preOrderType } = useBoothPreOrders(boothId);
	const { booths } = useBooths();
	const { booths: boothsWithTotals } = useBoothsWithTotals();
	const totals = useMemo(() => {
		return boothsWithTotals.reduce<number>((acc, booth) => {
			return acc + booth.totals[3];
		}, 0);
	}, [boothsWithTotals]);
	const [previousBooth, nextBooth] = usePreviousAndNextBooths(booths, boothId);
	const totalsByWeek = useTotalsByWeek(preOrders, boothId);
	const dispatch = useDispatch();
	// const totalWeight = useTotalWeight();
	const booth = useMemo(() => {
		const booth = booths.find(b => b.boothId === boothId);
		return booth;
	}
		, [booths, boothId]);

	const updateQuantity = (action: PreOrderAction, article: Article, quantity: number) => {
		dispatch(updatePreOrderActionArticleQuantity(action, article, quantity, preOrderType));
	};

	if (!booth) return null;

	return (
		<div className='TradeFairBoothHolder'>
			<div className='TradeFairBooth'>
				<div className='Stand'>
					Stand {booth?.boothId}
				</div>
				<CharlesTitle alt>
					{booth?.name}
				</CharlesTitle>

				<div className='legend'>
					<div className='article'>{t('tradefair.articleLabel')}</div>
					<div className='weeks'>
						{tradeFairWeeks.map(weekNr => (
							<div className='week' key={weekNr}>{t('tradefair.weekLabel', weekNr.split('.')[1])}</div>
						))}
					</div>
				</div>
				{preOrders && <TradeFairArticlesList
					preOrders={preOrders}
					updateQuantity={updateQuantity}
					deliveryWeek={booth.deliveryWeek}
				/>}

				<div className='total'>
					<div className='total-label'>{t('tradefair.totalLabel')}</div>
					<div className='weeks'>
						{totalsByWeek.map(total => (
							<div key={total.weekNr} className='week'>
								{(total.items !== 0 || total.articles !== 0) && (
									<>
										<span>
											{total.items}
											{total.freeItems !== 0 && <span className='free'> + {total.freeItems}</span>}
											{t('tradefair.unitsLabel')}
										</span>
										<span>{t('tradefair.articlesLabel', total.articles)}</span>
										<span>
											{(total.standardPoints || 0) + (total.tradeFairPoints || 0)}
											{/* <span>({total.standardPoints} + {total.tradeFairPoints})</span> */} coins
										</span>
									</>
								)}
							</div>
						))}
					</div>
				</div>

				<div className='bottom-padding'></div>

				<div className='navigation'>
					<span className='nav-left'>
						{previousBooth && <ButtonLink
							to={`/trade-fair-charles-koerse/${previousBooth.boothId}`}
							color='gold'
							icon={<FaArrowLeft />}
						>
							{previousBooth.name}
						</ButtonLink>}
					</span>

					<div className='middle'>
						<ButtonLink to={'/trade-fair-charles-koerse'} color='gold'>
							{t('tradefair.backToOverviewButton')}
						</ButtonLink>

						<TradeFairPoints points={totals} />
					</div>


					<span className='nav-right'>
						{nextBooth && <ButtonLink
							to={`/trade-fair-charles-koerse${nextBooth.boothId}`}
							color='gold'
							icon={<FaArrowRight />}
							iconPosition='right'
						>
							{nextBooth.name}
						</ButtonLink>}
					</span>
				</div>
			</div>
		</div>
	);
}
