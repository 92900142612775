
import './productsSummary.scss';
import { FaExclamationTriangle, FaTrashAlt } from 'react-icons/fa';
import { t } from './../../../libraries/i18n';
import ProductBadges from '../productBadges/productBadges';
import placeholder from '../../../images/placeholder.png';
import { useProductPoints } from '../../../libraries/hooks/useProductPoints';
import { useSelector } from 'react-redux';
import { selectDeliveryDate, selectProductById, selectProductPointsById } from '../../../modules/selectors';
import { tradeFairDates } from '../../../constants';
import { Coin } from '@abm-international/react-components';
import { CommonQuantityInput } from '@abm-international/react-components';
import { useMemo } from 'react';
import { useAppSelector } from '../../../libraries/hooks/redux';

interface ProductSummaryArticleProps {
    quantities: any;
    issues: any;
    removeProduct: any;
    confirmMadQuantity: any;
    canUpdateQuantity: () => boolean;
    canShowMadQuantityWarning: () => boolean;
    onQuantityChange: (quantity: any, product: any) => void;
    product: any;
    points: any;
    isTradeFair?: boolean;
}

export const ProductSummaryArticle = (props: ProductSummaryArticleProps) => {
    const {
        quantities, product: _product, issues, removeProduct, confirmMadQuantity, canUpdateQuantity, canShowMadQuantityWarning, onQuantityChange, points: _points, isTradeFair
    } = props;


    const points = useSelector(state => selectProductPointsById(state, _product?.id));
    const deliveryDate = useSelector(selectDeliveryDate, (a: Date | null, b: Date | null) => a?.getTime() === b?.getTime());
    const article = useAppSelector(state => selectProductById(state, _product.id));

    const productPoints = useProductPoints(points, deliveryDate, tradeFairDates);
    const orderQuantity = quantities[_product.id];
    let totalQuantity = orderQuantity || 0;
    if (_product.orderAmount) totalQuantity = totalQuantity * _product.orderAmount;
    if (!!_product?.awardedPromo?.quantity) totalQuantity += _product.awardedPromo.quantity;

    const totalPoints = useMemo(() => {
        return (_points?.standardPoints || 0) + (_points?.tradeFairPoints || 0);
    }, [_points]);

    const product: any = useMemo(() => {
        return {
            ..._product,
            ...article
        };
    }, [_product, article]);

    return (
        <li key={product.id} className={`product__item ${totalQuantity === 0 ? 'product__item--disabled' : ''}`}>
            <div className={'item__image'}>
                {product.images?.PS[0] ? (
                    <img src={`${process.env.REACT_APP_PORTAL_IMAGE_URL}/resources/images/articles/1200/${product.images.PS[0]}`} alt={product.name} />
                ) : (
                    <img src={placeholder} alt={`This is a placeholder of ${product.name}`} />
                )}
            </div>

            <div className={'item__body'}>
                <div className={'product__title'}>
                    <span className={'product__code'}>{product.code}</span>
                    <h4 className={'product__name'}>{product.name}</h4>
                </div>


                {!!product.awardedPromo && (
                    <div className={'product__promo'}>
                        {t('order.productPromoAwarded')}
                    </div>
                )}

                {issues && issues[product.id] && (
                    <div className={'product__notifications'}>
                        {issues[product.id]?.map((issue: any, index: number) => (
                            <div className={'product__notification'} key={index}>
                                <FaExclamationTriangle />
                                {issue}
                            </div>
                        ))}
                    </div>
                )}
                <div className='flexHorizontal'>
                    <Coin points={[productPoints.standardPoints]} />
                    <Coin koerse points={[productPoints.tradeFairPoints]} />
                </div>
                {!isTradeFair && <ProductBadges product={{ eob: product.eob }} />}

            </div>

            <div className={'item__right'}>
                <div className={'right__top'}>
                    {canUpdateQuantity() && totalQuantity === 0 && (
                        <button className={'item__delete'} onClick={() => removeProduct(product)}>
                            <FaTrashAlt />
                        </button>
                    )}

                    {canUpdateQuantity() ? (
                        <span className={'quantity'}>
                            <CommonQuantityInput
                                quantity={product.quantity}
                                quantityTimestamp={product.quantityTimestamp}
                                awardedPromo={product.awardedPromo?.quantity}
                                onQuantityChange={(e: { quantity: number, timestamp: number }) => onQuantityChange(e, product)}
                                disabled={!product.canOrder}
                                orderAmount={product.orderAmount}
                                madQuantityIndicator={product.showMadQuantityWarning}
                                focus={false}
                                size={'compact'}
                                t={t}
                            />
                        </span>
                    ) : (
                        <span className={'quantity'}>
                            {!!product?.awardedPromo?.quantity && (
                                <div className={'quantity__breakdown'}>
                                    <span className={'quantity__product'}>{product.quantity || 0}</span>
                                    <span className={'quantity__promo'}>{product.awardedPromo.quantity}</span>
                                </div>
                            )}

                            {product.orderAmount !== 1 && (
                                <div className={'quantity__breakdown'}>
                                    <span className={'quantity__product'}>{product.quantity || 0}</span>
                                    <span className={'quantity__ppq'}>{product.orderAmount}</span>
                                </div>
                            )}

                            <span className={'quantity__total'}>
                                {totalQuantity} <span>{totalQuantity === 1 ? t('order.reviewUnitLabel') : t('order.reviewUnitLabelMultiple')}</span>
                            </span>
                            {/* todo Sander */}
                            {/* {points && <span className='quantity__points'>
                                {totalPoints} <span>{totalPoints === 1 ? 'point' : 'points'}</span>
                            </span>} */}
                        </span>
                    )}
                </div>


                {product.showMadQuantityWarning && canShowMadQuantityWarning() && (
                    <div className={'mad-quantity__confirm'}>
                        {t('order.reviewMadQuantityQuestion', orderQuantity)}
                        <div className={'confirm__options'}>
                            <span className={'confirm__option confirm__option--accept'} onClick={() => confirmMadQuantity(product)}>{t('order.reviewMadQuantityYes')}</span>
                        </div>
                    </div>
                )}
            </div>
        </li>
    );
};
