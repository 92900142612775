import React, { useEffect, useMemo, useState } from 'react';
import './TradeFairOverview.scss';
// import { CharlesTitle } from '@abm-international/react-components';
import useBooths, { BEURS_2022_PREORDER_TYPE, BEURS_2024_PREORDER_TYPE } from './../../api/tradeFair/useBooths';
import { selectHasSubmittedAllPreOrdersByType, selectIsProductsFetching, selectPreOrdersActions } from '../selectors';
import { useAppSelector } from './../../libraries/hooks/redux';
import useCustomerRoute from './../../libraries/hooks/useCustomerRoute';
import TradeFairOverviewBooth from './TradeFairOverviewBooth';
import beursLogo from './../../images/Charles_koerse_DEF.jpg';
import {
	Button,
} from '@abm-international/react-components';
import PortalModal from '../../components/PortalModal/PortalModal';
import TradeFairSummary from './TradeFairSummary';
import promoTypes from '../products/promoTypes';
import { t } from './../../libraries/i18n';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { usePoints } from '../../libraries/hooks/usePoints';
import { tradeFairDates } from '../../constants';
import dateHelper from '../../libraries/dateHelper';
import { TradeFairPoints } from './TradeFairPoints';

export const useBoothsWithTotals = () => {
	const { booths, isFetching } = useBooths();
	const preOrdersActions = useAppSelector(state => selectPreOrdersActions(state, BEURS_2022_PREORDER_TYPE));
	const { calculateTotalPoints } = usePoints(tradeFairDates, undefined, true);

	const boothsWithTotals = booths.map(booth => {
		const totals = preOrdersActions.reduce((totals, poAction) => {
			if (poAction.boothId !== booth.boothId) return totals;

			const { standardPoints, tradeFairPoints } = calculateTotalPoints(poAction.articles, poAction.quantities, dateHelper.getDateOfISOWeek(booth.deliveryWeek.split('.')[1], booth.deliveryWeek.split('.')[0]));

			const itemsTotal = Object.values(poAction.quantities).reduce((total, value) => total += value, 0);
			const freeItemsTotal = poAction.articles.reduce((total, article) => {
				if (!article.promo) return total;
				if (article.promo.type !== promoTypes.FREE_BY_UNIT) return total;

				const quantity = poAction.quantities?.[article.id];
				if (!quantity || quantity === 0) return total;

				const freeItems = Math.floor(quantity / article.promo.trigger.num) * article.promo.target.num;

				return total + freeItems;
			}, 0);
			const articlesTotal = Object.values(poAction.quantities).reduce((total, value) => total += value !== 0 ? 1 : 0, 0);

			return [
				totals[0] + itemsTotal,
				totals[1] + articlesTotal,
				totals[2] + freeItemsTotal,
				totals[3] + standardPoints + tradeFairPoints
			];
		}, [0, 0, 0, 0]);

		const pending = preOrdersActions.reduce((totals, poAction) => {
			if (poAction.boothId !== booth.boothId) return totals;

			const pendingTotal = Object.values(poAction.orderedQuantities).reduce((total, value) => total += value, 0);
			const freePendingTotal = Object.values(poAction.freeQuantities).reduce((total, value) => total += value, 0);

			return [
				totals[0] + pendingTotal,
				totals[1] + freePendingTotal
			];
		}, [0, 0, 0]);

		return {
			...booth,
			totals,
			pending
		};
	});

	return {
		booths: boothsWithTotals,
		isFetching
	};
};
//const LOADINGPHRASES = ['voorbereiden', 'test1', 'test2', 'test3', 'voorbereiden', 'test1', 'test2', 'test3'];

export default function TradeFairOverview(props: any) {
	useCustomerRoute();
	const { booths, isFetching } = useBoothsWithTotals();

	const [showSummary, setShowSummary] = useState(false);
	///const totalWeight = useTotalWeight();//weg
	const hasSubmittedAll = useAppSelector(state => selectHasSubmittedAllPreOrdersByType(state, BEURS_2024_PREORDER_TYPE));
	const isFetchingProducts = useAppSelector(selectIsProductsFetching);


	const [progress, setProgress] = useState(0);
	const [bufferProgress, setBufferProgress] = useState(10);
	//const [loadingPhrase, setLoadingPhrase] = useState('voorbereiden');
	//const [indexLoadingPhrase, setIndexLoadingPhrase] = useState(0);

	const [loaderStatus, setLoaderStatus] = useState(booths.length !== 0 ? 'DONE' : 'NONE');
	const [buuferLoaderStatus, setBufferLoaderStatus] = useState(booths.length !== 0 ? 'DONE' : 'NONE');

	useEffect(() => {

		if (isFetchingProducts && loaderStatus === 'NONE') {
			setLoaderStatus('LOADING');
		}

		if (isFetching && buuferLoaderStatus === 'NONE') {
			setBufferLoaderStatus('LOADING');
		}

		if (!isFetchingProducts && loaderStatus === 'LOADING') {
			setLoaderStatus('ALMOST-DONE');

			setTimeout(() => {
				setLoaderStatus('DONE');
			}, 2000);
		}

		if (!isFetching && buuferLoaderStatus === 'LOADING') {
			setBufferLoaderStatus('ALMOST-DONE');

			setTimeout(() => {
				setBufferLoaderStatus('DONE');
			}, 2000);
		}


	}, [loaderStatus, isFetching, isFetchingProducts]);

	useEffect(() => {
		const LOADER_TIME = 30_000;

		const timer = setInterval(() => {
			if (loaderStatus === 'ALMOST-DONE') {
				setProgress(100);
				clearInterval(timer);
				return;
			}

			setProgress((oldProgress) => {
				const diff = 1;
				return Math.min(oldProgress + diff, 100);
			});
		}, LOADER_TIME / 100);

		return () => clearInterval(timer);
	}, [loaderStatus]);


	useEffect(() => {
		const LOADER_TIME = 6_000;

		const bufferTimer = setInterval(() => {
			if (buuferLoaderStatus === 'ALMOST-DONE') {
				setBufferProgress(100);
				clearInterval(bufferTimer);
				return;
			}

			setBufferProgress((oldProgress) => {
				const diff = 1;
				return Math.min(oldProgress + diff, 100);
			});
		}, LOADER_TIME / 100);

		return () => clearInterval(bufferTimer);
	}, [buuferLoaderStatus]);

	// useEffect(() => {
	// 	if (Math.random() >= 0.4) {
	// 		setLoadingPhrase(LOADINGPHRASES[indexLoadingPhrase]);
	// 		setIndexLoadingPhrase(indexLoadingPhrase + 1);
	// 	}
	// }, [progress]);

	useEffect(() => {
		if (showSummary) document.body.style.overflow = 'hidden';
		if (!showSummary) document.body.style.overflow = 'unset';

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, [showSummary]);

	const totals = useMemo(() => {
		return booths.reduce<number>((acc, booth) => {
			return acc + booth.totals[3];
		}, 0);
	}, [booths]);

	return (
		<div className='TradeFairOverview'>
			<img className='logo' src={beursLogo} alt='Food event of quality & tradition - Charles - 87 years' />

			{!hasSubmittedAll && (
				<>
					{(loaderStatus !== 'DONE' || isFetchingProducts) &&
						<div className='loader'>
							<h1>{t('tradefair.loading')}</h1>
							{/* <p>{loadingPhrase}</p> */}
							<p>{t('tradefair.thisCanTakeAWhile')}</p>
							<Box sx={{
								width: '60%', maxWidth: '100rem'
							}}>
								<LinearProgress
									sx={{
										borderRadius: '100rem', display: 'flex', justifyContent: 'center',
										alignItems: 'center'
									}}
									color="inherit" variant="buffer" value={progress} valueBuffer={bufferProgress} />
							</Box>
						</div>
					}

					{(loaderStatus === 'DONE' && !isFetchingProducts) && (
						<>
							<div className='booths'>
								{booths && booths.map(booth => <TradeFairOverviewBooth key={booth.boothId} booth={booth} />)}
							</div>
							<div className='actions'>
								<Button primary onClick={() => setShowSummary(true)}>
									{t('tradefair.verifyAndSend')}
								</Button>
								{/* to do total points (pas op voor promos 2 + 1 gratis dus 3 keer die punten dan) */}
								{/* <div className='total-weight'>
                                    {t('tradefair.totalWeightLabel')}
                                    <span>
                                        {t('tradefair.weightInKgLabel', totalWeight)}
                                    </span>
                                </div> */}

								<TradeFairPoints points={totals} />
							</div>
						</>
					)}

					{showSummary && (
						<PortalModal close={() => setShowSummary(false)} className='TradeFairSummaryModal'>
							<TradeFairSummary />
						</PortalModal>
					)}
				</>
			)}
			{hasSubmittedAll && (
				<div className='submitted'>
					<div className={'checkmark'}></div>
					<div className={'success__message'}>
						{t('order.reviewOrderSent')}
					</div>
					<Button onClick={() => window.location.reload()} color='green'>
						{t('order.start_new_order')}
					</Button>
				</div>
			)}
		</div>
	);
}
